import PropTypes from "prop-types";
import React from "react";
import { Link } from "gatsby";

import "../components/bootstrap.min.css";
import "../components/vendor/tether/tether.min.css";
import "../components/vendor/bootstrap/css/bootstrap.min.css";
import "../components/css/fonts/express-icons.css";
import "../components/css/card.css";

import "../components/vendor/font-awesome/css/font-awesome.min.css";
import "../components/vendor/bootstrap/css/glyphicon.css";
import "../components/vendor/cubeportfolio/css/cubeportfolio.min.css";

//import '../components/vendor/ion-icons/css/ionicons.min.css',
import "../components/vendor/owl-carousel/owl.theme.css";
import "../components/vendor/owl-carousel/owl.carousel.css";
import "../components/vendor/magnific-popup/magnific-popup.css";
import "../components/vendor/lite-tooltip/css/litetooltip.css";
import "../components/vendor/PricingTablesInspiration/css/component.css";
import "../components/vendor/PricingTablesInspiration/css/icons.css";
import "../components/vendor/PricingTablesInspiration/css/demo.css";
//Theme CSS
import "../components/css/main.css";
import "../components/css/main-shortcodes.css";
import "../components/css/header.css";
import "../components/css/form-element.css";
import "../components/css/animation.css";
import "../components/css/bg-stylish.css";
import "../components/css/blog.css";
import "../components/css/streamline-icon.css";
import "../components/css/responsive.css";
import "../components/css/utilities.css";
import "../components/css/portfolio.css";
import "../components/css/font-icons.css";
import "../components/css/skins/default.css";

//Theme Custom CSS
import "../components/css/custom.css";

const Footer = ({ siteTitle }) => (
  <footer class="footer stylelamas" id="footer-1">
    <div class="main">
      <div class="container">
        <div class="row">
          <div class="col-md-3 col-sm-6">
            <section>
              <div class="heading">
                <h4 class="title">Company</h4>
              </div>
              <ul class="list-unstyled">
                <li>
                  {" "}
                  <a href="/faq">FAQs</a>{" "}
                </li>
                <li>
                  {" "}
                  <a href="/terms-of-use">Terms of Service</a>{" "}
                </li>
                <li>
                  {" "}
                  <a href="/privacy">Privacy Policy</a>{" "}
                </li>
                <li>
                  {" "}
                  <a href="/contact-us">Contact Us</a>{" "}
                </li>
              </ul>
            </section>
          </div>

          <div class="col-md-3 col-sm-6">
            <section>
              <div class="heading">
                <h4 class="title">Products</h4>
              </div>
              <ul class="list-unstyled">
                <li>
                  {" "}
                  <a href="contact/index.html">Motion Editor</a>{" "}
                </li>
                <li>
                  {" "}
                  <a href="contact/index.html">Video Edior</a>{" "}
                </li>
                <li>
                  {" "}
                  <a href="/contact-us">Image Editor</a>{" "}
                </li>
                <li>
                  {" "}
                  <a href="/contact-us">Ditial Asset Manager</a>{" "}
                </li>
              </ul>
            </section>
          </div>

          <div class="col-md-3 col-sm-6">
            <section>
              <div class="heading">
                <h4 class="title">Support</h4>
              </div>
              <ul class="list-unstyled">
                <li>
                  {" "}
                  <a href="contact/index.html">Customers</a>{" "}
                </li>
                <li>
                  {" "}
                  <a href="contact/index.html">Features</a>{" "}
                </li>
                <li>
                  {" "}
                  <a href="/contact-us">Status</a>{" "}
                </li>
              </ul>
            </section>
          </div>

          <div class="col-md-3 col-sm-6">
            <section>
              <div class="heading">
                <h4 class="title">Social Media</h4>
              </div>
              <div class="clearfix social-wrap ">
                <ul class="list-inline">
                  <li>
                    <a href="http://twitter.com/pubwoof">
                      <span class="btn btn-sm btn-default">
                        <i class="fa  fa-2x fa-twitter"></i>
                      </span>
                      <div class="meta clearfix"></div>
                    </a>
                  </li>

                  <li>
                    <a href="http://facebook.com/pubwoof">
                      <span class="btn btn-sm btn-default">
                        <i class="fa  fa-2x fa-facebook"></i>
                      </span>
                      <div class="meta clearfix"></div>
                    </a>
                  </li>

                  <li>
                    <a href="http://instagram.com/pubwoof">
                      <span class="btn btn-sm btn-default">
                        <i class="fa  fa-2x fa-instagram"></i>
                      </span>
                      <div class="meta clearfix"></div>
                    </a>
                  </li>

                  <li>
                    <a href="http://youtube.com/pubwoof">
                      <span class="btn btn-sm btn-default">
                        <i class="fa  fa-2x fa-youtube"></i>
                      </span>
                      <div class="meta clearfix"></div>
                    </a>
                  </li>
                </ul>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>

    <div class="copyright">
      <div class="container">
        <div class="row">
          <div class="col-sm-6">
            <p class="mb-0">© 2019 Pubwoof. All right reserved.</p>
          </div>
          <div class="col-sm-6">
            <div class="clearfix pull-right">
              <ul class="list-inline fs-13 mb-none">
                <li>
                  <a href="pages-about.html">About</a>
                </li>
                <li>
                  <a href="blog-grid.html">Blog</a>
                </li>
                <li>
                  <a href="pages-contact.html">Contact</a>
                </li>
                <li>
                  <a href="#">Terms</a>
                </li>
                <li>
                  <a href="#">Jobs</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
);

Footer.propTypes = {
  siteTitle: PropTypes.string
};

Footer.defaultProps = {
  siteTitle: ``
};

export default Footer;
