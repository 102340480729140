import PropTypes from "prop-types";
import React from "react";
import "../components/bootstrap.min.css";
import "../components/vendor/tether/tether.min.css";
import "../components/vendor/bootstrap/css/bootstrap.min.css";
import "../components/css/fonts/express-icons.css";
import "../components/css/card.css";

import "../components/vendor/font-awesome/css/font-awesome.min.css";
import "../components/vendor/bootstrap/css/glyphicon.css";
import "../components/vendor/cubeportfolio/css/cubeportfolio.min.css";

//import '../components/vendor/ion-icons/css/ionicons.min.css',
import "../components/vendor/owl-carousel/owl.theme.css";
import "../components/vendor/owl-carousel/owl.carousel.css";
import "../components/vendor/magnific-popup/magnific-popup.css";
import "../components/vendor/lite-tooltip/css/litetooltip.css";
import "../components/vendor/PricingTablesInspiration/css/component.css";
import "../components/vendor/PricingTablesInspiration/css/icons.css";
import "../components/vendor/PricingTablesInspiration/css/demo.css";
//Theme CSS
import "../components/css/main.css";
import "../components/css/main-shortcodes.css";
import "../components/css/header.css";
import "../components/css/form-element.css";
import "../components/css/animation.css";
import "../components/css/bg-stylish.css";
import "../components/css/blog.css";
import "../components/css/streamline-icon.css";
import "../components/css/responsive.css";
import "../components/css/utilities.css";
import "../components/css/portfolio.css";
import "../components/css/font-icons.css";
import "../components/css/skins/default.css";

//Theme Custom CSS
import "../components/css/custom.css";

const Header = ({ siteTitle }) => (
  <header id="header" class="header-narrow header-dark">
    <div class="header-body">
      <div class="header-container container">
        <div class="header-row">
          <div class="header-column">
            <div class="header-row">
              <div class="header-logo">
                <a href="/">
                  <img
                    alt="Express"
                    width="100"
                    height="50"
                    src="/img/logo.png"
                  />
                </a>
              </div>
            </div>
          </div>

          <div class="header-column justify-content-end">
            <div class="header-row">
              <div class="header-nav header-nav-top-line justify-content-end">
                <div class="header-nav-main header-nav-main-effect-2 header-nav-main-sub-effect-1">
                  <nav class="collapse">
                    <ul class="nav nav-pills" id="mainNav">
                      <li class="">
                        <a class="nav-link" href="/earn">
                          <button
                            type="button"
                            class="btn btn-outline-default btn-rounded fw-6 btn-sm ls-0 pt-10 pb-10 mb-0"
                          >
                            <i class="fa fa-dollar ml-none" />
                            Earn Money!
                          </button>
                        </a>
                      </li>

                      <li class="">
                        <a
                          class="nav-link"
                          href="https://app.pubwoof.com/creators#/login"
                        >
                          <button
                            type="button"
                            class="btn btn-outline-primary btn-rounded fw-6 btn-sm ls-0 pt-10 pb-10 mb-0"
                          >
                            <i class="fa fa-lock ml-none" />
                            Login
                          </button>
                        </a>
                      </li>
                      <li class="">
                        <a
                          class="nav-link"
                          href="https://app.pubwoof.com/creators"
                        >
                          <button
                            type="button"
                            class="btn btn-outline-primary btn-rounded fw-6 btn-sm ls-0 pt-10 pb-10 mb-0"
                          >
                            <i class="fa fa-lock ml-none" />
                            Sign Up
                          </button>
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
);

Header.propTypes = {
  siteTitle: PropTypes.string
};

Header.defaultProps = {
  siteTitle: ``
};

export default Header;
