import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Footer from "../components/footer";

const NotFoundPage = () => (

  <Layout>
<div class="container">

<section class="section-primary" >

   <div class="container text-center pt-30 pb-20">
      <div class="row">

         <div class="col-md-12">

            <h3 class="fw-6 fs-120 primary-color"> 404!</h3>
            <p class="fs-40 fw-5 ">Page not found!!</p>
            <hr class="invisible" />
            <a href="/" class="btn btn-primary btn-rounded btn-md"><i class="fa fa-home"></i> Home</a>
         </div>
      </div>
   </div>
</section>
</div>

  </Layout>
)

export default NotFoundPage
